

.linkStyle{
    text-decoration: none  !important;
    color: blanchedalmond;
    margin-left: 10px;
}

.innerDiv{
    width: 90%;
    margin: auto;
}

.userName, .LogOut{
    font-size: 18px;
    margin-left: 2px;
}
.userName2{
    /* font-size: 15px; */
}
@media(max-width:700px) 
{
    .siteName,.userName{
        font-size: 15px;
    }
}

@media(max-width:550px) 
{
    .siteName,.userName{
        font-size: 14px;
    }

    .innerDiv{
        width: 100%;
        margin: auto;
    }
}

@media(max-width:400px) 
{
    .siteName,.userName{
        font-size: 10px;
    }

    
}

.innerDiv a{
    color: white;
    font-size: 25px ;
    line-height: 25px;
}


.MenuImage{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 3px solid black;
}