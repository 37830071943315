.ProductImage{
    height: 300px;
    width: 100%;
    border-radius: 10px;
}
.TitleProduct,p{
    text-align: left;
    font-weight: bold;
    font-family: 20px;
    margin: 10px;
    color: #fff;
}

.SpanBlock{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 20px;
}

.TitleProduct{
    font-size: 23px;
    font-style: italic;
    font-weight: 600;
}