.CheckOutTable button{
    margin-top: 30px;
}

.CheckOutTable{
    margin: 50px;
}

.CheckOutTable h3{
    text-align: center;
}